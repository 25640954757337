import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import MainContainer from "../../../layouts/container/mainContainer";
import { getUserInfo } from "../../../services/authService";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/buttons/button";
import { FileTypeId } from "../../../constants/enum";
import {
  callApi,
  uploadImages,
} from "../../../services/apiService";
import { NotificationManager } from "react-notifications";

const urlBase = process.env.REACT_APP_URL_BASE;

const ModalAttachFile = ({ open, handleClose, setSignPath, loadDataFile }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [botonActivo, setBotonActivo] = useState(false);
  const [document, setDocument] = useState([]);

  var userInfo = getUserInfo();
  const userId = {
    userId: userInfo.id,
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    file_type_id: {
      required: {
        value: true,
        message: t("validationError.file_type_id.require"),
      },
    },
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("image", data.image[0]);
    formData.append("file_type_id", FileTypeId.HealthConditions);

    if (
      document.find(
        (element) =>
          element.file_type_id == data.file_type_id &&
          data.file_type_id == FileTypeId.HealthConditions
      )
    ) {
      NotificationManager.warning(t("files.validations.duplicated"));
      return;
    } else {
      setBotonActivo(true);
      callApi(
        () => uploadImages(formData),
        (data) => {
          setSignPath(data.path);
          NotificationManager.success(t("template.uploadSucces"));
          loadDataFile();
          handleClose();
        }
      );
    }
    setBotonActivo(false);
  });

  const cleanInputs = () => {
    setValue("file_type_id", "");
    setValue("file", "");
  };

  return (
    <>
      {/* ModalAdjuntar */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <a
                href={
                  "/download/Form/Concentimiento condiciones pre-adquiridas.pdf"
                }
                download="Consentimiento condiciones pre-adquiridas"
                target="_blank"
              >
                {t("healthInfo.download")}
              </a>
              <div className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                  disabled={botonActivo}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default ModalAttachFile;
