import React, { useEffect, useState } from "react";
import "../../styles/pages/login.scss";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import JSZip from "jszip";
import { useTranslation } from "react-i18next";
import {
  callApi,
  getUsers,
  getImages,
  getMessagesPostulation,
  pdfGeneratorSigned,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import Button from "../../components/buttons/button";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import Modal from "@mui/material/Modal";
import { BsCameraReelsFill } from "react-icons/bs";
import { ImStack } from "react-icons/im";
import { ImFileText } from "react-icons/im";
import {
  AiFillEye,
  AiOutlineDownload,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDislike,
  AiFillWechat,
  AiOutlineSwap,
  AiOutlineTeam,
  AiOutlineSolution,
  AiOutlineBorderlessTable,
} from "react-icons/ai";
import ModalTypeAndComment from "./modals";
import ChangeCourseModal from "./changeCourseModal";
import DeclinePostulationModal from "./declinePostulationModal";
import { generalStatesPostulation, FileTypeId } from "../../constants/enum";
import DownloadPdfModal from "./downloadPdfModal";
import EmployerListModal from "./employerListModal";
import CvModal from "./cvModal";
import OrderModal from "./orderModal";
import { FaPlane } from "react-icons/fa";
import FligthDataModal from "./fligthDataModal";

const urlBase = process.env.REACT_APP_URL_BASE;

const UserList = () => {
  const { t } = useTranslation();
  const [travelerList, setTravelerList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [messagesPostulation, setMessagesPostulation] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [previewView, setPreviewModal] = useState(false);
  const [deletePath, setDeletePath] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [openGeneratePdf, setOpenGeneratePdf] = useState(false);
  const [openModalMsg, setOpenModalMsg] = useState(false);
  const [openEmployerList, setOpenEmployerList] = useState(false);
  const [openCv, setOpenCv] = useState(false);
  const [openFligthData, setOpenFligthData] = useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [openUrlVideo, setopenUrlVideo] = useState(false);
  const [openDeclineVideo, setOpenDeclineVideo] = useState(false);
  const [openChangeCourse, setOpenChangeCourse] = useState(false);
  const [openDeclineProgram, setOpenDeclineProgram] = useState(false);
  const [desition, setDesition] = useState(false);
  const [statePostulationGeneral, setStatePostulationGeneral] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [downloadUrl, setDownloadUrl] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseD = () => setOpenDeclineVideo(false);
  const handleOpenGeneratePdf = () => setOpenGeneratePdf(true);
  const handleOpenEmployerList = () => setOpenEmployerList(true);
  const handleOpenCv = () => setOpenCv(true);
  const handleOpenFligthData = () => setOpenFligthData(true);
  const handleOpenOrder = () => setOpenOrder(true);

  const handleOpenChangeCourse = (row) => {
    if (
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declined ||
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declinedClosed ||
      row.statePostulationGeneral == generalStatesPostulation.declined ||
      row.statePostulationGeneral == generalStatesPostulation.declinedClosed
    ) {
      NotificationManager.warning(t("listTraveler.postulationDeclined"));
      return;
    }
    setOpenChangeCourse(true);
  };
  const handleCloseChangeCourse = () => setOpenChangeCourse(false);

  const handleOpenDeclineProgram = (row) => {
    if (
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declined ||
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declinedClosed ||
      row.statePostulationGeneral == generalStatesPostulation.declined ||
      row.statePostulationGeneral == generalStatesPostulation.declinedClosed
    ) {
      NotificationManager.warning(t("listTraveler.postulationDeclined"));
      return;
    }
    setOpenDeclineProgram(true);
  };
  const handleCloseDeclineProgram = () => setOpenDeclineProgram(false);
  const handleCloseodalMsg = () => setOpenModalMsg(false);
  const handleCloseGeneratePdf = () => setOpenGeneratePdf(false);
  const handleCloseEmployerList = () => setOpenEmployerList(false);
  const handleCloseCv = () => setOpenCv(false);
  const handleCloseFligthData = () => setOpenFligthData(false);
  const handleCloseOrder = () => setOpenOrder(false);

  const generatePdf = (id) => {
    callApi(
      () => pdfGeneratorSigned({ id: id }),
      (x) => {
        setDownloadUrl(x.path);
      }
    );
  };

  useEffect(() => {
    loadDataTable();
    setUserInfo(getUserInfo());
  }, []);

  const loadDataTable = () => {
    callApi(getUsers, (data) => {
      setTravelerList(data);
    });
  };

  const getObjPost = (tableMeta) => {
    return travelerList.find((x) => x.postulation_id == tableMeta.rowData[0]);
  };

  const getObj = (tableMeta) => {
    return travelerList.find((x) => x.id == tableMeta.rowData[0]);
  };

  const columns = [
    {
      name: "postulation_id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("orderPostulation", "userList.order"),
    defaultCol("course_name", "postulation.course"),
    defaultCol("lastName", "register.lastName"),
    defaultCol("name", "register.name"),
    defaultCol("email", "Mail"),
    defaultCol("telephone", "register.phone"),
    {
      name: "statePostulationGeneralName",
      label: t("userList.stateGeneral"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("interviewWorkDate", "general.interviewWork"),
    defaultCol("interviewConsularDate", "general.interviewConsular"),
    {
      name: "statePostulationGeneral",
      label: t("userList.files"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, traveler, updateValue) => {
          var rowObjPost = getObjPost(traveler);
          return (
            <div className="table-btn-group">
              {parseInt(value) >= generalStatesPostulation.uploadingFiles &&
                parseInt(value) < generalStatesPostulation.declined && (
                  <Button
                    label={<ImFileText />}
                    onClick={() => {
                      generatePdf(rowObjPost.id);
                      handleOpenGeneratePdf();
                    }}
                    tooltip={t("postulation.title")}
                  />
                )}
              {parseInt(value) < generalStatesPostulation.declined && (
                <Button
                  label={<ImStack />}
                  onClick={() => {
                    setCurrentUser(rowObjPost);
                    handleOpen();
                    loadFiles(rowObjPost.id, rowObjPost.postulation_id);
                  }}
                  tooltip={t("userList.files")}
                />
              )}
              <Button
                label={<AiFillWechat />}
                onClick={() => {
                  setCurrentUser(rowObjPost);
                  getMessagesPostulationFunction(rowObjPost.postulation_id);
                }}
                tooltip={t("userList.tooltipMessages")}
              />
              <Button
                label={<AiOutlineSolution />}
                onClick={() => {
                  setCurrentUser(rowObjPost);
                  handleOpenCv();
                }}
                tooltip={t("cv.title")}
              />
              {parseInt(value) < generalStatesPostulation.declined &&
                parseInt(value) >=
                  generalStatesPostulation.employerSelection && (
                  <Button
                    label={<AiOutlineTeam />}
                    onClick={() => {
                      setCurrentUser(rowObjPost);
                      handleOpenEmployerList();
                    }}
                    tooltip={t("userList.tooltipEmployers")}
                  />
                )}
              {parseInt(value) < generalStatesPostulation.declined &&
                rowObjPost &&
                rowObjPost.video_url && (
                  <a href={rowObjPost.video_url} target="_blank">
                    <Button
                      label={<BsCameraReelsFill />}
                      tooltip={t("userList.tooltipVideo")}
                    />
                  </a>
                )}
              {parseInt(value) < generalStatesPostulation.declined &&
                parseInt(value) >=
                  generalStatesPostulation.flightAndAccommodation && (
                  <Button
                    onClick={() => {
                      setCurrentUser(rowObjPost);
                      handleOpenFligthData();
                    }}
                    label={<FaPlane />}
                    tooltip={t("userList.flightInformation")}
                  />
                )}
            </div>
          );
        },
      },
    },
    {
      name: "statePostulationGeneral",
      label: t("userList.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, travelerList, updateValue) => {
          var rowObj = getObjPost(travelerList);
          return (
            <div className="table-btn-group">
              {parseInt(value) < generalStatesPostulation.declined && (
                <>
                  <Button
                    label={<AiOutlineBorderlessTable />}
                    onClick={() => {
                      setCurrentUser(rowObj);
                      handleOpenOrder(rowObj);
                    }}
                    tooltip={t("userList.order")}
                  />
                  <Button
                    label={<AiOutlineSwap />}
                    onClick={() => {
                      setCurrentUser(rowObj);
                      handleOpenChangeCourse(rowObj);
                    }}
                    tooltip={t("userList.tooltipChange")}
                  />
                  <Button
                    label={<AiOutlineClose />}
                    onClick={() => {
                      setCurrentUser(rowObj);
                      setDesition(false);
                      openModalDecisionFun(rowObj, false);
                    }}
                    tooltip={t("userList.tooltipRejectStep")}
                  />
                  <Button
                    label={<AiOutlineCheck />}
                    onClick={() => {
                      setCurrentUser(rowObj);
                      setDesition(true);
                      openModalDecisionFun(rowObj, true);
                    }}
                    tooltip={t("userList.tooltipApproveStep")}
                  />
                  <Button
                    type="ww-btn-secondary"
                    label={<AiOutlineDislike />}
                    onClick={() => {
                      setCurrentUser(rowObj);
                      handleOpenDeclineProgram(rowObj);
                    }}
                    tooltip={t("userList.tooltipDecline")}
                  />
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const columnsMsg = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("date", "msg.table.date"),
    {
      name: "statePostulationGeneralName",
      label: t("msg.table.stateProcess"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("comment", "msg.table.comment"),
  ];

  const loadFiles = (userId, postulationId) => {
    callApi(
      () => getImages({ userId: userId, postulationId: postulationId }),
      (data) => {
        setDocuments(data);
      }
    );
  };
  const getObjFiles = (tableMeta) =>
    documents.find((x) => x.id === tableMeta.rowData[0]);

  const columnsModal = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "file_type_id",
      label: t("files.type"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObjFile = getObjFiles(documents);
          switch (rowObjFile.file_type_id) {
            case FileTypeId.Picture:
              return t("form.picture");
            case FileTypeId.PassportVigent:
              return t("form.passportvigent");
            case FileTypeId.Certify:
              return t("form.certify");
            case FileTypeId.GeneralConditions:
              return t("form.conditions");
            case FileTypeId.ExVisaJ1:
              return t("form.ExVisaJ1");
            case FileTypeId.Other:
              return t("form.other");
            case FileTypeId.Cv:
              return t("form.CV");
            case FileTypeId.PostulationForm:
              return t("form.postulationForm");
            case FileTypeId.Flight:
              return t("general.flight");
            case FileTypeId.PassportVigent2:
              return t("form.passportvigent2");
            case FileTypeId.HealthConditions:
              return t("form.healthConditions");
            default:
              return t("form.other");
          }
        },
      },
    },
    {
      name: "Actions",
      //label: t("Link"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObjFile = getObjFiles(documents);
          return (
            <div className="table-btn-group">
              <Button
                label={<AiFillEye />}
                onClick={() => {
                  setPreviewModal(true);
                  setDeletePath(rowObjFile.path);
                }}
              />
              <a
                href={urlBase + deletePath.replace("WorldWideFiles", "")}
                download
                target="_blank"
              >
                <Button
                  label={<AiOutlineDownload />}
                  onClick={() => {
                    setDeletePath(rowObjFile.path);
                  }}
                />
              </a>
            </div>
          );
        },
      },
    },
  ];

  const getMessagesPostulationFunction = (postulationId) => {
    callApi(
      () => getMessagesPostulation({ postulationId: postulationId }),
      (data) => {
        setMessagesPostulation(data);
      }
    );
    setOpenModalMsg(true);
  };

  function getCurrentDate() {
    const fechaActual = new Date();
    const año = fechaActual.getFullYear();
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // Meses son base 0
    const día = fechaActual.getDate().toString().padStart(2, "0");
    return `${año}-${mes}-${día}`;
  }

  const handleDownloadZIP = async () => {
    const zip = new JSZip();
    const listaDePaths = documents.map((objeto) => ({
      path: urlBase + objeto.path.replace("WorldWideFiles", ""),
      name: objeto.file_name,
    }));
    try {
      await Promise.all(
        listaDePaths.map(async (archivo) => {
          const response = await axios.get(archivo.path, {
            responseType: "blob",
          });
          const ext = archivo.path.split(".").pop().toLowerCase();
          let nombreArchivo = archivo.name;
          if (ext === "pdf") {
            nombreArchivo = nombreArchivo + ".pdf";
          }
          zip.file(nombreArchivo, response.data);
        })
      );
      zip.generateAsync({ type: "blob" }).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          currentUser.course_name +
          "_" +
          currentUser.name +
          "_" +
          currentUser.lastName +
          "_" +
          getCurrentDate() +
          "_" +
          ".zip";
        a.click();
      });
    } catch (error) {
      console.error(`Error al descargar los archivos ficticios`, error);
    }
  };

  const [stateModalDesicion, setStateModalDesicion] = useState(0);

  const openModalDecisionFun = (obj, desicion) => {
    setStatePostulationGeneral(obj.statePostulationGeneral);
    if (
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declined ||
      currentUser.statePostulationGeneral ==
        generalStatesPostulation.declinedClosed ||
      obj.statePostulationGeneral == generalStatesPostulation.declined ||
      obj.statePostulationGeneral == generalStatesPostulation.declinedClosed
    ) {
      NotificationManager.warning(t("listTraveler.postulationDeclined"));
      return;
    }
    setStateModalDesicion(0);
    setOpenDeclineVideo(true);
  };

  return (
    <>
      <MainContainer class="ww-container-xxl" showImage={false}>
        <h1 className="title">{t("userList.title")}</h1>
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={travelerList}
          columns={columns}
          options={defaultOptions()}
        />
      </MainContainer>
      <Footer />
      {/* MODAL TABLA DE DOCUMENTOS */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <Table
              data={documents}
              columns={columnsModal}
              options={defaultOptions()}
            />
            <div className="table-btn-group">
              <Button
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("File.Zip")}
                onClick={handleDownloadZIP}
              />
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.close")}
                onClick={handleClose}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL PREVISUALIZACIÓN DOCUMENTOS */}
      <Modal
        open={previewView}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>
          <embed
            src={urlBase + "/" + deletePath.replace("WorldWideFiles", "")}
            className="file-preview-view"
          />
          <div>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => setPreviewModal(false)}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL VIDEO URL */}
      <Modal
        open={openUrlVideo}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <h1 className="title">{t("userList.title.aprove")}</h1>
          <div>
            <a href={videoUrl} target="_blank">
              <Button size="btn-s" label={t("button.videoSee")} />
            </a>
          </div>
          <div className="table-btn-group">
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => {
                setopenUrlVideo(false);
              }}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL TABLA MENSAJES */}
      <Modal
        open={openModalMsg}
        onClose={handleCloseodalMsg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer Nologo={true} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <Table
              data={messagesPostulation}
              columns={columnsMsg}
              options={defaultOptions()}
            />
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={handleCloseodalMsg}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODALS ANSWER PROCESS */}
      <ModalTypeAndComment
        loadDataTable={loadDataTable}
        open={openDeclineVideo}
        onClose={handleCloseD}
        currentUser={currentUser}
        desition={desition}
        statePostulationGeneral={statePostulationGeneral}
      />
      {/* MODAL CHANGE COURSE */}
      <ChangeCourseModal
        loadDataTable={loadDataTable}
        open={openChangeCourse}
        onClose={handleCloseChangeCourse}
        currentUser={currentUser}
      />
      <DeclinePostulationModal
        loadDataTable={loadDataTable}
        open={openDeclineProgram}
        onClose={handleCloseDeclineProgram}
        currentUser={currentUser}
      />
      {/* MODAL DESCARGA PDF */}
      <DownloadPdfModal
        open={openGeneratePdf}
        onClose={handleCloseGeneratePdf}
        url={downloadUrl}
      />
      {/* MODAL EMPLOYER LIST */}
      <EmployerListModal
        openEmployerList={openEmployerList}
        handleCloseEmployerList={handleCloseEmployerList}
        postulationId={currentUser.postulation_id}
      />
      <CvModal
        open={openCv}
        close={handleCloseCv}
        postulationId={currentUser.postulation_id}
        userId={currentUser.id}
        isBlocked={false}
      />
      <OrderModal
        open={openOrder}
        close={handleCloseOrder}
        postulationId={currentUser.postulation_id}
        loadDataTable={loadDataTable}
        fullName={currentUser.lastName + " " + currentUser.name}
        orderPostulation={currentUser.orderPostulation}
      />
      <FligthDataModal
        open={openFligthData}
        close={handleCloseFligthData}
        postulationId={currentUser.postulation_id}
      />
    </>
  );
};

export default UserList;
