import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import MainContainer from "../../../layouts/container/mainContainer";
import { getUserInfo } from "../../../services/authService";
import Button from "../../../components/buttons/button";

const urlBase = process.env.REACT_APP_URL_BASE;

const ModalPreview = ({ open, handleClose, signPath }) => {
  const { t } = useTranslation();

  console.log("signFile", signPath);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
           <p>{t("form.previewView")}</p>
         {signPath != "" && signPath != undefined && (
            <>
              <embed
                src={urlBase + "/" + signPath.replace("WorldWideFiles", "")}
                className="file-preview-view"
              />
            </>
          )}
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => handleClose()}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default ModalPreview;
