import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import MainContainer from "../../../layouts/container/mainContainer";
import { getUserInfo } from "../../../services/authService";
import Button from "../../../components/buttons/button";
import CanvaSignRaw from "./canvaSignRaw";

const ModalSignFile = ({ open, handleClose, setSignPath }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);

  var userInfo = getUserInfo();
  const userId = {
    userId: userInfo.id,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>

          {isAndroid ? (
            <a
              href={
                "/download/Form/Concentimiento condiciones pre-adquiridas.pdf"
              }
              download
            >
              <Button size="btn-m" label={t("form.download")} />
            </a>
          ) : (
            <embed
              src={
                "/download/Form/Concentimiento condiciones pre-adquiridas.pdf"
              }
              className="file-preview-view"
            />
          )}
          <CanvaSignRaw
            onClose={handleClose}
            userId={userId}
            setSignPath={setSignPath}
            open={open}
          />
        </MainContainer>
      </Modal>
    </>
  );
};

export default ModalSignFile;
